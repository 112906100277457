import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import { Toast } from "vant";
import supplier from "./supplier"; //供应商相关
import operate from "./operate"; //运营端相关
import client from "./client"; //运营端相关
import shareOff from "./shareOff"; //推荐官相关
import warehouse from "./warehouse"; //运营端---仓库相关
import financial from "./financial"; //运营端---财务审核相关
const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/",
      redirect: "/choice",
    },
    {
      path: "/choice", //登录注册起始页
      name: "Choice",
      meta: {
        navBarFlag: false,
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/choice"),
    },
    {
      path: "/register", //注册
      name: "Register",
      meta: {
        navBarFlag: true,
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/register"),
    },
    {
      path: "/login", //密码登录
      name: "Login",
      meta: {
        navBarFlag: false,
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/login"),
    },
    {
      path: "/verifyLogin", //验证码登录
      name: "VerifyLogin",
      meta: {
        navBarFlag: true,
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/login/verifyLogin"),
    },
    {
      path: "/verify", //验证码
      name: "Verify",
      meta: {
        navBarFlag: true,
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/verify"),
    },
    {
      path: "/setPwd", //初次设置密码
      name: "SetPwd",
      meta: {
        navBarFlag: true,
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/passWord/setPwd"),
    },
    {
      path: "/resetPwd", //重置密码
      name: "ResetPwd",
      meta: {
        navBarFlag: true,
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/passWord/resetPwd"),
    },
    {
      path: "/search", //搜索过渡页
      name: "Search",
      meta: {
        navBarFlag: false,
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/search"),
    },
    {
      path: "/searchResult", //搜索结果页-供应商
      name: "SearchResult",
      meta: {
        navBarFlag: false,
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/search/result.vue"),
    },
    {
      path: "/searchResultClient", //搜索结果页-客户
      name: "SearchResultClient",
      meta: {
        navBarFlag: false,
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/search/resultClient.vue"),
    },

    //******判断当前角色进行跳转
    {
      path: "/initPage",
      name: "InitPage",
      component: () => import("@pages/index"),
    },
    
    {
      path: "/initPageShareProduct",//供产品分享链接使用
      name: "InitPageShareProduct",
      component: () => import("@pages/initPage/shareProduct.vue"),
    },
    {
      path: "/initPageShareCouponList",//供优惠券分享链接使用
      name: "InitPageShareCouponList",
      component: () => import("@pages/initPage/shareCouponList.vue"),
    },
    {
      path: "/InitPageShareSearch",//供搜索结果分享链接使用
      name: "InitPageShareSearch",
      component: () => import("@pages/initPage/shareSearch.vue"),
    },
    {
      path: "/initPageShareClassification",//供客户分类页分享链接使用
      name: "InitPageShareClassification",
      component: () => import("@pages/initPage/shareClassification.vue"),
    },
    
    ...supplier, //供应商相关
    ...operate, //运营端相关
    ...client, //客户相关
    ...shareOff,//推荐官相关
    ...warehouse, //仓库相关
    ...financial, //财务审核相关

    {
      path: "/home", //首页
      name: "home",
      meta: {
        tabBarFlag: true,
        requiredAuth: true,
      },
      component: () => import("@pages/home"),
    },
    {
      path: "/sort", //分类
      name: "sort",
      meta: {
        tabBarFlag: true,
        requiredAuth: true,
      },
      component: () => import("@pages/sort"),
    },
    {
      path: "/cart", //购物车
      name: "cart",
      meta: {
        tabBarFlag: true,
        requiredAuth: true,
      },
      component: () => import("@pages/cart"),
    },
    {
      path: "/viewPriSupp", //协议-商家
      name: "viewPriSupp",
      meta: {
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/viewPrivice/supp.vue"),
    },
    {
      path: "/viewPriClient", //协议-客户
      name: "viewPriSupp",
      meta: {
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/viewPrivice/client.vue"),
    },
    {
      path: "/aboutUs", //关于我们
      name: "aboutUs",
      meta: {
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/aboutUs/index.vue"),
    },
    {
      path: "/maVideoView", //视频播放
      name: "MaVideoView",
      meta: {
        tabBarFlag: false,
        requiredAuth: false,
      },
      component: () => import("@pages/maVideoView/index.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {

  // if(to.path!="/choice" || to.path!="/login" || to.path!="/register"){
  //      // 定义正则表达式
  //     const regex = /\?.+?(?=#)/g;
  //     window.location.href=window.location.href.replace(regex, '')
  // }
   
  // if(to.path == "/productDetail"){
  //   next()
  //   return;
  // }
  if (to.path != "/login" && to.meta.requiredAuth) {
    if (
      JSON.parse(sessionStorage.getItem("userInfoXpz")) &&
      JSON.parse(sessionStorage.getItem("userInfoXpz")).access_token
    ) {
      next();
    } else {
      Toast("请重新登录后操作!");
      if (
        JSON.parse(sessionStorage.getItem("userInfoXpz")) &&
        JSON.parse(sessionStorage.getItem("userInfoXpz")).accountType
      ) {
        let accountType = JSON.parse(
          sessionStorage.getItem("userInfoXpz")
        ).accountType;
        next({ path: "/login", query: { type: accountType } });
      } else {
        if (to.path == "/productDetail") {
          // 获取当前页面的完整 URL
          const currentURL = window.location.href;

          // 使用正则表达式匹配 URL 中的查询参数部分
          const parameterMatch = currentURL.match(/\?(.*)/);

          if (parameterMatch) {
            // 如果有匹配到参数部分
            const queryString = parameterMatch[1];

            // 将查询参数字符串解析为对象
            const params = {};
            queryString.split("&").forEach((pair) => {
              const [key, value] = pair.split("=");
              params[key] = decodeURIComponent(value);
            });
            console.log(params,'paramsparams')
            // 使用 params 获取特定参数的值
            const parameterValueId = params.id;
            const parameterValueAccountId = params.accountId;
            if(parameterValueId && parameterValueAccountId){
              next({ path: "/login", query: { xpzShareProductId: parameterValueId,xpzShareAccountId:parameterValueAccountId,type:1 } });
            }
          }

         
        } else {
          next("/login");
        }
      }
    }
  } else {
    next();
  }
});

export default router;
